/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../css/style.scss"

const Layout = ({ children, cssClasses = "" }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    useEffect(() => {
        var element = document.getElementsByClassName("site-wrap")
        element[0].classList.add("loaded")
    })

    return (
        <div className={`site-wrap ` + cssClasses}>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main id="main" className="site-main">
                {children}
            </main>
            <Footer siteTitle={data.site.siteMetadata.title} />
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
