import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import Menu from "../components/menu"

const Footer = ({ siteTitle }) => {
    const data = useStaticQuery(graphql`
        query {
            subscribeImage: file(relativePath: { eq: "read_message.png" }) {
                childImageSharp {
                    fixed(width: 24) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            logoImage: file(relativePath: { eq: "logo-full.png" }) {
                childImageSharp {
                    fixed(width: 144) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            wpgraphql {
                menus {
                    nodes {
                        name
                        slug
                        menuId
                        menuItems {
                            nodes {
                                url
                                label
                                menuItemId
                                linkRelationship
                                target
                                connectedObject {
                                    ... on WPGraphQL_Page {
                                        id
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const menusWpGraph = data.wpgraphql.menus.nodes
    let secondFooterMenu = {}
    let footerMenu = {}

    if (menusWpGraph instanceof Array) {
        menusWpGraph.forEach(menuElement => {
            if (menuElement.hasOwnProperty("slug")) {
                switch (menuElement.slug) {
                    case "second-footer-menu":
                        secondFooterMenu = menuElement
                        break
                    case "footer-menu":
                        footerMenu = menuElement
                        break
                    default:
                        break
                }
            }
        })
    }

    return (
        <div className="footer-wrap">
            <footer id="footer" className="footer">
                <div className="footer-menu">
                    <div id="nav_menu-2" className="widget widget_nav_menu">
                        <h4>Links</h4>
                        <div className="menu-second-footer-menu-container">
                            {footerMenu.hasOwnProperty("menuItems") && (
                                <Menu
                                    items={footerMenu.menuItems.nodes}
                                    menuId="menu-footer-menu"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="footer-menu">
                    <div id="nav_menu-3" className="widget widget_nav_menu">
                        <h4>Resources</h4>
                        <div className="menu-second-footer-menu-2-container">
                            {secondFooterMenu.hasOwnProperty("menuItems") && (
                                <Menu
                                    items={secondFooterMenu.menuItems.nodes}
                                    menuId="menu-second-footer-menu"
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="subscribe-widget">
                    <div className="widget widget_subscribe">
                        <h4>
                            <Img
                                fixed={
                                    data.subscribeImage.childImageSharp.fixed
                                }
                                alt="Subscribe"
                            />
                            Subscribe
                        </h4>
                        <p>
                            Subscribe to our newsletter to receive updates and
                            new releases via email.
                        </p>
                        <div id="mc_embed_signup">
                            <form
                                action="//quemalabs.us2.list-manage.com/subscribe/post?u=c7f4fee4a41dd96279da5efa9&amp;id=a1bd04ef36"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                className="validate"
                                target="_blank"
                            >
                                <div id="mc_embed_signup_scroll">
                                    <div className="mc-field-group">
                                        <label htmlFor="mce-EMAIL">
                                            Email Address
                                        </label>
                                        <input
                                            type="email"
                                            placeholder="Enter your email..."
                                            name="EMAIL"
                                            className="required email"
                                            aria-label="Email"
                                            id="mce-EMAIL"
                                        />
                                        <input
                                            type="submit"
                                            value="Subscribe"
                                            name="subscribe"
                                            id="mc-embedded-subscribe"
                                            className="button"
                                        />
                                    </div>
                                    <div id="mce-responses" className="clear">
                                        <div
                                            className="response"
                                            id="mce-error-response"
                                            style={{ display: "none" }}
                                        ></div>
                                        <div
                                            className="response"
                                            id="mce-success-response"
                                            style={{ display: "none" }}
                                        ></div>
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "-5000px",
                                        }}
                                        aria-hidden="true"
                                    >
                                        <input
                                            type="text"
                                            name="b_c7f4fee4a41dd96279da5efa9_a1bd04ef36"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="sub-footer">
                <div className="footer-logo">
                    <Link to="/" aria-label={siteTitle}>
                        <Img
                            fixed={data.logoImage.childImageSharp.fixed}
                            alt={siteTitle}
                        />
                    </Link>
                </div>

                <div className="sub-footer-social-menu">
                    <ul className="social-menu">
                        <li className="social-item">
                            <a
                                href="https://www.facebook.com/QuemaLabs/"
                                aria-label="Facebook"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#444444"
                                >
                                    <path
                                        d="M 5 3 C 3.897 3 3 3.897 3 5 L 3 19 C 3 20.103 3.897 21 5 21 L 11.621094 21 L 14.414062 21 L 19 21 C 20.103 21 21 20.103 21 19 L 21 5 C 21 3.897 20.103 3 19 3 L 5 3 z M 5 5 L 19 5 L 19.001953 19 L 14.414062 19 L 14.414062 15.035156 L 16.779297 15.035156 L 17.130859 12.310547 L 14.429688 12.310547 L 14.429688 10.574219 C 14.429687 9.7862188 14.649297 9.2539062 15.779297 9.2539062 L 17.207031 9.2539062 L 17.207031 6.8222656 C 16.512031 6.7512656 15.814234 6.71675 15.115234 6.71875 C 13.041234 6.71875 11.621094 7.9845938 11.621094 10.308594 L 11.621094 12.314453 L 9.2773438 12.314453 L 9.2773438 15.039062 L 11.621094 15.039062 L 11.621094 19 L 5 19 L 5 5 z"
                                        fill="#444444"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li className="social-item">
                            <a
                                href="https://www.instagram.com/quema_labs/"
                                aria-label="Instagram"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#444444"
                                >
                                    <path
                                        d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
                                        fill="#444444"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li className="social-item">
                            <a
                                href="https://twitter.com/QuemaLabs"
                                aria-label="Twitter"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 30 30"
                                    fill="#444444"
                                >
                                    <path
                                        d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"
                                        fill="#444444"
                                    />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="sub-footer-copy">
                    Copyright © {new Date().getFullYear()} {siteTitle}. All
                    rights reserved.
                </div>
            </div>
        </div>
    )
}

Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: ``,
}

export default Footer
