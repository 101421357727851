import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "../components/menu"
import { isLoggedIn } from "../utils/auth"

const Header = ({ siteTitle }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    wpUrl
                }
            }
            wpgraphql {
                menus {
                    nodes {
                        name
                        slug
                        menuId
                        menuItems {
                            nodes {
                                url
                                label
                                menuItemId
                                linkRelationship
                                target
                                connectedObject {
                                    ... on WPGraphQL_Page {
                                        id
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const menusWpGraph = data.wpgraphql.menus.nodes
    let mainMenu = {}

    if (menusWpGraph instanceof Array) {
        menusWpGraph.forEach(menuElement => {
            if (menuElement.hasOwnProperty("slug")) {
                switch (menuElement.slug) {
                    case "nav-menu":
                        mainMenu = menuElement
                        break
                    default:
                        break
                }
            }
        })
    }

    return (
        <header id="header">
            <div className="site-logo-wrap">
                <Link to="/" aria-label={siteTitle} className="sitle-logo-link">
                    <svg
                        width="34"
                        height="50"
                        viewBox="0 0 34 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0)">
                            <path
                                d="M31.3071 30.5307C29.6463 27.9865 28.4095 25.7604 28.1268 22.5095C27.8795 19.6826 28.3389 17.8099 28.9749 16.1491C29.4343 14.9477 29.2223 14.771 28.0208 15.2303C26.1834 15.9371 24.1339 17.6685 23.0738 19.2586C22.2965 20.4247 21.8371 19.612 22.1904 18.6226C23.2859 15.5484 23.7806 12.7569 22.6851 8.69325C21.7311 5.23035 19.2576 2.08547 15.83 0.248017C14.5933 -0.423361 14.6286 0.38936 15.0526 1.48477C16.148 4.27629 16.042 7.17381 15.1587 10.142C13.9219 14.241 10.9537 17.4918 7.80883 20.7427C1.44841 27.3505 -3.28658 35.7957 2.86183 43.5696C5.08798 46.3611 9.92897 50.0007 16.5721 50.0007C22.4731 50.0007 26.5014 47.3505 27.6675 46.4318C33.1798 42.0148 34.982 36.2197 31.3071 30.5307ZM16.7134 42.4035C11.5544 42.4035 9.01024 38.4459 8.51554 36.3964C8.44487 36.1491 8.93957 36.1137 9.08091 36.3258C9.50494 36.9618 10.6003 37.7392 11.2011 37.8805C11.5544 37.9512 11.7664 37.6332 11.5544 37.3505C9.82296 35.1597 9.82296 32.6155 10.7417 30.566C11.8371 28.1279 15.2293 24.877 16.7488 21.6261C17.4201 20.1773 17.5261 18.6226 17.5261 17.9159C17.5261 17.5272 17.9502 17.4565 18.1268 17.8452C20.141 22.4388 15.3353 26.5024 17.8442 30.9194C18.0562 31.3081 18.4802 31.7675 18.7982 32.0148C19.1162 32.2975 19.3989 31.8735 19.3636 31.5554C19.1869 30.0007 19.6463 27.7038 21.3777 26.1844C21.6251 25.9724 21.9431 26.2197 21.8724 26.6084C21.0597 30.9547 24.0986 31.5201 24.3813 35.2657C24.5579 38.1986 21.4131 42.4035 16.7134 42.4035Z"
                                fill="black"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect
                                    width="33.2155"
                                    height="50"
                                    fill="white"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                    <svg
                        version="1.1"
                        id="logo-words"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 552.7 125.7"
                        style={{ enableBackground: "new 0 0 552.7 125.7" }}
                    >
                        <g>
                            <path
                                className="st0"
                                fill="#000000"
                                d="M536.9,55.1c0,1.6,9.1,5.8,13.2,2c1.4-1.3,2.8-4.8,2.5-8.6c-0.5-6.1-10.1-11.9-22-11.9
		c-13.7,0-23.7,6.6-24.4,14.9c-1.6,17.9,28.9,19.6,27.5,32.5c-0.5,4.5-3.4,8.1-8,8.1c-9.2-0.1-9.5-10-5.7-14.9
		c-2.5-3.4-12.8-5.4-15.4,0.6c-3.9,9,3.1,21.1,22,21.1c12.9,0,21.8-6.1,22.7-15.9c1.6-16.2-29.1-20.5-28-32.2
		c0.6-6.3,5.4-8.7,9.3-8.8C538.7,42.2,539.5,50.1,536.9,55.1z"
                            />
                        </g>
                        <path
                            className="st0"
                            fill="#000000"
                            d="M406.8,111.7c-16.4,0-31.2-11.3-40-12.6c7.4-4.1,8.5-27.4,9.5-39.6c1.8-22.8,4.7-53.7,6.4-58.4
	c-1.1-0.6-13.9-1.5-15.6,4.6c-1.9,6.6-4,38.8-6.2,59.6c-2.2,20.2-3,28.7-15.4,34c-4.7,2-5.1,12.8-3.8,15.1
	c5.6-4.2,15.1-5.6,25.7-2.4c14.7,4.5,21.8,13.6,36,13.6c13.8,0,17.6-15.6,16.8-19.9C418.3,107.8,415.2,111.7,406.8,111.7z"
                        />
                        <path
                            className="st0"
                            fill="#000000"
                            d="M86.4,111.7c-15.7,0-29.5-12.5-39.5-12.8C60.6,91.4,70.4,71,72.6,50.6C75.3,26.2,66.2,0,41.7,0
	C15.5,0,3,29.3,0.5,51.1C-1.7,70.5,2.7,89.6,16.7,99c-2.8,1.7-3.2,12.9-2.5,15.2c4.2-3.1,16.4-5.7,28.8-2.7
	c15.8,3.9,26.3,13.9,39.8,13.9c10.9,0,18.7-9.5,18.2-20C98.4,108.2,93.2,111.7,86.4,111.7z M17.1,50.7c2.3-19.3,11.2-39.3,24.5-39.1
	c13.3,0.2,17.3,18.2,14.7,39.7C54.1,69,47.4,87.8,32.6,87.8C19.6,87.8,14.8,69.8,17.1,50.7z"
                        />
                        <path
                            className="st0"
                            fill="#000000"
                            d="M85.2,40.7c-1.3,4.7-2.7,12.7-3.8,30.9C80.3,91,81.9,98.8,92.6,98.9c10,0,18.9-10,19.6-11.3
	c0,4.5,1.2,11.3,10.2,11.3c10.2,0,19.5-9.5,20.6-10.8c-0.3-1.7-1.7-5-2.2-5.9c-0.9,3-6.3,5.8-8.9,5.8c-3.3,0-5.3-1.4-4.6-13.6
	c0.8-13.6,3-27.8,4.8-37c-2-0.5-13.7-1.8-15.4,2.3c-1.3,3.1-3.4,24.4-4.2,38.4c-1.6,5.5-8.8,9.8-11.8,9.8c-3.9,0-4.7-3.3-4.5-12.8
	c0.3-13.4,2.6-27.8,4.1-37.4C97.3,36.7,86.5,35.8,85.2,40.7z"
                        />
                        <path
                            className="st0"
                            fill="#000000"
                            d="M153.8,79.6c5.6,0,27.2-3.9,33.2-20.4c4.3-12-3.2-22.6-16.4-22.5c-16.6,0.2-30.4,12.6-32.3,31.4
	C136,90.7,152,98.9,163.8,98.9c12.4,0,23.2-5.6,29.4-12.8c1.2-2.9,1-6.8,1.2-10.8c-2.6,6-13.7,12.6-25.7,12.6
	c-11.8,0-16.3-8.8-15.1-20.6c1-9.5,5.7-22.5,15.2-22.5c7.2,0.1,5.6,10.5,1.1,16.6c-4.3,5.8-11.6,10.2-17.9,11.1
	C151.4,76,149.8,80.4,153.8,79.6z"
                        />
                        <path
                            className="st0"
                            fill="#000000"
                            d="M198.1,37.3c0.1,4-1,17.6-2.5,28.6c-1.2,9.4-3.3,25.4-2.5,28.5c0.9,3.8,7.6,5.7,14.4,3.5c0-7,1.3-19.2,2.4-29.9
	c1.1-10.7,10.2-20.8,15.1-20.8c5.2,0,3,7.3,1.3,26.2c-1.4,15.9-1.8,20.1-1,22.2c1.4,3.5,11.8,4.2,14,2.4c-0.2-4.4,0.3-12.3,2.1-27.5
	c1.7-14,8.9-23.1,14.9-23.1c4.1,0,2.2,9.2,0.8,22c-2,17.1-4.4,29.5,9.1,29.5c10.2,0,18.5-10.1,19-10.5c-0.6-2-2.4-3.5-2.7-4.7
	c-1.1,1.5-3.8,4.2-7.3,4.2c-4.8,0-4.2-4.8-2-27.3c2-19.9,0.8-24-9.3-24.1c-8.7-0.1-17.4,7-20.3,11.8c0.5-4.7-0.6-11.6-9.8-11.6
	c-9.9,0-17.1,6.2-21.6,10.7c0.2-1.1,0.9-4.3,0.7-6.2C212.3,36.8,202.5,36.2,198.1,37.3z"
                        />
                        <path
                            className="st0"
                            fill="#000000"
                            d="M317.6,54.8c3.8,0.1,3.5-1.3,6.4-1.5c-0.1-2.5-1.4-17.1-15.8-16.7c-13.6,0.3-23.9,11.7-26.1,32.5
	c-2.2,20.7,5.8,29.8,16.7,29.8c7.9,0,14.6-7.7,16-10.2c0.8,4.3,6.2,10.2,13.2,10.2c9.2,0,16.7-7.1,18.2-10.4c0.8-2-0.7-5.7-1.4-6.2
	c-1.5,2.4-6.8,5.6-10,5.6c-3.8,0-5.5-1.9-4.2-15.4c1.2-13.6,2.7-30.2,3.6-34.5c-5.5-2.3-15.1-1.9-15.6,2.6c-0.5,5.7-2.9,30.9-3.7,38
	c-0.5,4.8-5.1,9.4-9.2,9.4c-5.8,0-9.9-5-8.3-20.7c1.5-14.8,7.4-23.2,13.3-23.3C315.9,43.7,318,48.7,317.6,54.8z"
                        />
                        <path
                            className="st0"
                            fill="#000000"
                            d="M422.3,54.8c3.8,0.1,3.5-1.3,6.4-1.5c-0.1-2.5-1.4-16.7-15.8-16.7c-13.6,0-23.9,11.7-26.1,32.5
	c-2.2,20.7,5.8,29.8,16.7,29.8c8.6,0,14.6-7.7,16-10.2c0.8,4.3,5.3,10.2,13.2,10.2c9.2,0,16.7-7.1,18.2-10.4c0.8-2-0.7-5.7-1.4-6.2
	c-1.5,2.4-5.9,5.6-9.3,5.6c-3.8,0-6.1-1.9-4.9-15.4c1.2-13.6,2.8-30.2,3.6-34.5c-5.5-2.3-15.1-1.9-15.5,2.6
	c-0.5,5.7-2.9,30.9-3.7,38c-0.5,4.8-5.3,9.4-9.4,9.4c-5.9,0-9.7-5-8.1-20.7c1.5-14.8,7.4-23.2,13.3-23.3
	C420.7,43.7,422.8,48.7,422.3,54.8z"
                        />
                        <path
                            className="st0"
                            fill="#000000"
                            d="M508.4,58.3c0.2-1.4-1.1-7.2-1.1-10.2c-0.7,3.6-3.7,5.5-6.6,5.4c-1,0-2.7-0.7-3.4-1.8
	c-1.8-5.8-6-15.1-20.4-15.1c-2.5,0-11.7,1-16.2,10.4c-0.7,3.2,2.6,7,4.1,8.2c0.8-5.7,5.6-11.3,11.1-11.3c7.8,0,7.8,12.9,6.3,24.1
	c-1.8,13-7.3,20-13.3,19.9c-6.7-0.1-6.4-12.2-3.5-37.3c3.1-26.5,4.5-46.1,5.6-49.5c-1.1-0.9-13.2-2.1-15.5,3.6
	c-1,2.5-2.8,23.2-4.4,40.5c-1.9,20-2.7,33.3-2.1,38.7c1,8.8,7.9,14.9,18.5,14.9c12.5,0,27.4-13.1,30.1-34.6c0.1-0.6,0.1-1.2,0.2-1.9
	C501.8,64.5,507.5,60.8,508.4,58.3z"
                        />
                    </svg>
                </Link>
            </div>
            <div className="site-menu-wrap">
                {mainMenu.hasOwnProperty("menuItems") && (
                    <Menu
                        items={mainMenu.menuItems.nodes}
                        menuId="site-menu"
                        menuClass="site-menu"
                    />
                )}
            </div>
            {true !== isLoggedIn() && (
                <div className="club-button-wrap">
                    <Link
                        to="/theme-club/"
                        className="club-button sub-hero-button"
                    >
                        Join The Club
                    </Link>
                </div>
            )}
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
