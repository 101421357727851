const isBrowser = typeof window !== `undefined`

const getUser = () =>
    window.localStorage.quemalabsUser
        ? JSON.parse(window.localStorage.quemalabsUser)
        : {}

const setUser = user =>
    (window.localStorage.quemalabsUser = JSON.stringify(user))

export const handleLogin = async ({ username, password }) => {
    if (!isBrowser) return false

    var formData = new FormData()

    formData.append("username", username)
    formData.append("password", password)

    var post_url = process.env.GATSBY_WP_URL + "wp-json/jwt-auth/v1/token"

    return await fetch(post_url, {
        method: "POST",
        body: formData,
    })
        .then(async response => {
            // Verificamos que obtuvimos una respuesto en json sino devolvemos como texto
            const contentType = response.headers.get("content-type")
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return await response.json()
            } else {
                return response.text()
            }
        })
        .then(async responseJson => {
            console.log(responseJson)

            if (responseJson.token) {
                console.log("setting user...")
                setUser(responseJson)
                return await responseJson
            } else {
                if (responseJson.data && responseJson.data.status) {
                    if (403 === responseJson.data.status) {
                        switch (responseJson.code) {
                            case "[jwt_auth] invalid_username":
                                return { usernameError: true }
                            case "[jwt_auth] incorrect_password":
                                return { passwordError: true }
                            default:
                                break
                        }
                    }
                }
            }
        })
        .catch(error => {
            console.error(error)
        })
}

export const isLoggedIn = () => {
    if (!isBrowser) return false

    const user = getUser()
    return !!user.token
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
    if (!isBrowser) return

    console.log(`Ensuring the \`quemalabsUser\` property exists.`)
    setUser({})
    callback()
}
