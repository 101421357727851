import { Link } from "gatsby"
import React from "react"
import { navigate } from "@reach/router"
import { isLoggedIn, logout } from "../utils/auth"

const Menu = props => {
    const { items, menuId, menuClass = "" } = props

    return (
        <ul id={menuId} className={`menu ${menuClass}`}>
            {items.map((footerItem, index) =>
                (() => {
                    if (footerItem.connectedObject.hasOwnProperty("slug")) {
                        let url = footerItem.connectedObject.slug
                        if ("home" === footerItem.connectedObject.slug) {
                            url = "/"
                        }
                        return (
                            <li
                                id={`menu-item-${footerItem.menuItemId}`}
                                className="menu-item"
                                key={index}
                            >
                                <Link to={url}>{footerItem.label}</Link>
                            </li>
                        )
                    } else {
                        return (
                            <li
                                id={`menu-item-${footerItem.menuItemId}`}
                                className="menu-item"
                                key={index}
                            >
                                <a href={footerItem.url}>{footerItem.label}</a>
                            </li>
                        )
                    }
                })()
            )}
            {(() => {
                if ("site-menu" === menuId) {
                    if (isLoggedIn()) {
                        return (
                            <React.Fragment>
                                <li
                                    id={`menu-item-purchases`}
                                    className="menu-item"
                                >
                                    <Link to="app/purchases/">Purchases</Link>
                                </li>

                                <li
                                    id={`menu-item-logout`}
                                    className="menu-item"
                                >
                                    <a
                                        href="/"
                                        onClick={event => {
                                            event.preventDefault()
                                            logout(() => navigate(`/app/login`))
                                        }}
                                    >
                                        Log Out
                                    </a>
                                </li>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <li id={`menu-item-login`} className="menu-item">
                                <Link to="app/login/">Login</Link>
                            </li>
                        )
                    }
                }
            })()}
        </ul>
    )
}

export default Menu
